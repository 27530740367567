import ObservationEntry from "../models/ObservationEntry";
import React from "react";
import "./AllEntries.scss";
import formatEntryDate from "../utils/formatEntryDate";
import { Button, ListGroup, ListGroupItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faX } from "@fortawesome/free-solid-svg-icons";

interface AllEntriesProps {
  entries: ObservationEntry[];
  setEntries(entries: ObservationEntry[]): void;
  setActiveEntry(index: number): void;
}

export default function AllEntries({
  entries,
  setEntries,
  setActiveEntry,
}: AllEntriesProps) {
  return (
    <ListGroup className="all_entries">
      {entries.map((entry, index) => (
        <ListGroupItem
          className="d-flex align-items-center item-entry"
          key={`entry_${entry.id}`}
          onClick={() => setActiveEntry(index)}
        >
          <div className="d-flex flex-grow-1 flex-column">
            <div className="created">{formatEntryDate(entry.created)}</div>
            <div className="comment">{entry.comment}</div>
          </div>
          <Button
            variant="danger"
            title="Observatie verwijderen"
            onClick={(e) => {
              e.stopPropagation();
              if (
                window.confirm(
                  `Wilt u de observatie van ${formatEntryDate(
                    entry.created
                  )} verwijderen?`
                )
              ) {
                const newEntries = entries.filter((e) => e.id !== entry.id);
                setEntries(newEntries);
                if (newEntries.filter((e) => e.id !== null).length <= 1) {
                  setActiveEntry(0);
                }
              }
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
}
