import React from "react";
import IndicatorCategory from "../models/IndicatorCategory";
import Indicator from "../models/Indicator";
import "./ChooseIndicator.scss";
import { ListGroup, ListGroupItem } from "react-bootstrap";

interface ChooseIndicatorProps {
  categories: IndicatorCategory[];
  onIndicatorChosen(indicator: Indicator): void;
}

export default function ChooseIndicator({
  categories,
  onIndicatorChosen,
}: ChooseIndicatorProps) {
  return (
    <ListGroup style={{ height: "75vh", overflow: "auto" }}>
      {categories.map((category, categoryIndex) => (
        <ListGroupItem key={categoryIndex}>
          <h3>{category.name}</h3>
          {category.description === "" ? null : (
            <p className="description">{category.description}</p>
          )}
          <ListGroup>
            {category.indicators.map((indicator, indicatorIndex) => (
              <ListGroupItem
                className="indicator_choice"
                key={indicatorIndex}
                onClick={() => {
                  onIndicatorChosen(indicator);
                }}
              >
                {indicator.code}. {indicator.description}
              </ListGroupItem>
            ))}
          </ListGroup>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
}
