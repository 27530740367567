import React, { useContext, useLayoutEffect, useMemo, useState } from "react";
import { OpenAPI } from "../api";
import User from "../models/User";
import { UserList } from "./UserList";
import "./AdminRoot.scss";

import Map, { MapView } from "../components/map/Map";
import { IndicatorContext } from "../components/IndicatorContext";
import UserData from "../models/UserData";
import Area from "../models/Area";
import Observation from "../models/Observation";
import RootRoute from "../RootRoute";
import EditObservation from "../components/EditObservation";
import OpenQuestions from "../components/OpenQuestions";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

type Selection =
  | {
      type: "area";
      area: Area;
      userId: number;
    }
  | { type: "observation"; observation: Observation }
  | null;

export default function AdminRoot() {
  // All data
  const [allUserData, setAllUserData] = useState<Record<number, UserData>>({});

  // Load user data from HTML
  const users = useMemo<User[]>(
    () =>
      JSON.parse(
        document.getElementById("users")?.textContent ?? "[]"
      ) as User[],
    []
  );

  // Set JWT token for API usage
  useLayoutEffect(() => {
    OpenAPI.TOKEN = document.getElementById("jwt")?.textContent ?? "";
  }, []);

  const initialView = useMemo<MapView>(
    () => ({
      center: { lat: 52.2, lng: 5.4 },
      zoom: 7,
    }),
    []
  );
  const { bottomUIHeight } = useContext(IndicatorContext);
  const [selection, setSelection] = useState<Selection>(null);
  const allObservations = useMemo(
    () => Object.values(allUserData).flatMap((u) => u.observations),
    [allUserData]
  );

  return (
    // Subtract approximate size of header
    // Note that these cannot be set in css. In a production build Maplibre's canvas
    // height will be set before the css will load
    <div className="admin_root" style={{ height: "calc(100vh - 12em)" }}>
      <div className="map" style={{ height: "100%" }}>
        <Map
          smallMarkers={allObservations
            .filter((o) =>
              selection?.type === "observation"
                ? o !== selection.observation
                : true
            )
            .map((o) => ({
              id: o.id,
              code: o.indicator.code,
              coordinate: o.coordinate,
              bagId: o.bagId,
            }))}
          selectedMarker={
            selection?.type === "observation"
              ? {
                  code: selection.observation.indicator.code,
                  coordinate: selection.observation.coordinate,
                  bagId: selection.observation.bagId,
                  draggable: false,
                }
              : null
          }
          availableHeight={100}
          areas={Object.values(allUserData).map((u) => u.area)}
          onSelectedMarkerDragged={() => {}}
          selectionMode={"both"}
          onSmallMarkerClicked={(id) => {
            const observation = allObservations.find((o) => o.id === id);

            if (observation !== undefined) {
              setSelection({
                type: "observation",
                observation: observation,
              });
            }
          }}
          onMapClicked={() => {}}
          focusCoordinate={null}
          initialView={initialView}
          selectedArea={selection?.type === "area" ? selection.area : null}
          onAreaMarkerClicked={(area) => {
            const userId = Number.parseInt(
              Object.entries(allUserData).find(([e, v]) => v.area === area)![0]
            );
            setSelection({
              type: "area",
              area: area,
              userId: userId,
            });
          }}
          showGeoLocationMarker={false}
        />
      </div>
      <UserList users={users} setAllUserData={setAllUserData} />
      <div
        id="bottom-ui"
        style={{
          width: "400px",
          height: `${bottomUIHeight}%`,
          opacity: bottomUIHeight === 0 ? 0.0 : 1.0,
        }}
      >
        <Button
          variant="secondary"
          id="close"
          size="sm"
          onClick={() => setSelection(null)}
        >
          <FontAwesomeIcon icon={faXmark} />
        </Button>
        {(() => {
          if (selection === null) {
            return <RootRoute />;
          } else if (selection.type === "observation") {
            return (
              <EditObservation
                key={selection.observation.id}
                readonly={true}
                categories={[]}
                onObservationSaved={() => {}}
                observation={selection.observation}
                onObservationChanged={() => {}}
                onObservationDeleted={() => {}}
              />
            );
          } else if (selection.type === "area") {
            return (
              <OpenQuestions
                questions={allUserData[selection.userId].openQuestions}
                setQuestions={() => {}}
                readonly={true}
              />
            );
          }
        })()}
      </div>
    </div>
  );
}
