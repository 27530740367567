import React, { useContext, useLayoutEffect, useMemo, useState } from "react";
import { ApiService, OpenAPI } from "../api";
import Login from "./Login";
import { jwtDecode } from "jwt-decode";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Terms from "./Terms";

interface UserSessionProps {
  children: JSX.Element;
}

interface User {
  userId: number;
}

interface UserSessionContextProps {
  user: User | null;
  logout: () => void;
}

const UserSessionContext = React.createContext<UserSessionContextProps>({
  user: null,
  logout() { },
});

interface Jwt {
  iat: number;
  user: number;
}

export default function UserSessionProvider({
  children,
}: UserSessionProps): JSX.Element {
  const [jwt, setJwt] = useState<string | null>(
    () => window.localStorage["jwt"] ?? null
  );

  const user = useMemo<User | null>(() => {
    if (jwt !== null) {
      const decoded = jwtDecode<Jwt>(jwt);
      if (decoded.user) {
        return { userId: decoded.user };
      }
    }
    return null;
  }, [jwt]);

  useLayoutEffect(() => {
    if (jwt === null) {
      OpenAPI.TOKEN = undefined;
    } else {
      OpenAPI.TOKEN = jwt;
    }
  }, [jwt]);

  async function login(password: string) {
    if (password === "Avans2021") {
      alert(
        "Gebruik het nieuwe wachtwoord wat je hebt ontvangen om in te loggen"
      );
      return;
    }

    try {
      const response = await ApiService.login({ password });
      window.localStorage["jwt"] = response.token;
      setJwt(response.token);
    } catch (e: any) {
      if (e.status === 403) {
        alert("Verkeerd wachtwoord");
      } else {
        alert(e);
      }
    }
  }

  if (user === null) {
    return (
      <>
        <Routes>
          <Route path="/terms" element={<Terms />}>
            {/* <Terms /> */}
          </Route>
          <Route path="/" element={<Login onLogin={login} />}>
          </Route>
        </Routes>
      </>
    );
  } else {
    return (
      <UserSessionContext.Provider
        value={{
          logout() {
            window.localStorage.removeItem("jwt");
            window.localStorage.removeItem("map_center_lat");
            window.localStorage.removeItem("map_center_lng");
            window.localStorage.removeItem("map_zoom");
            setJwt(null);
          },
          user,
        }}
      >
        {children}
      </UserSessionContext.Provider>
    );
  }
}

export function useLogout(): () => void {
  const { logout } = useContext(UserSessionContext);
  return logout;
}

export function useUser(): User {
  const { user } = useContext(UserSessionContext);
  return user!;
}
