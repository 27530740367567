import User from "../models/User";
import { ApiService } from "../api";
import "./UserList.scss";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useAsyncEffect from "../utils/useAsyncEffect";
import UserData from "../models/UserData";
import userDataFromApi from "../models/api/UserData";
import { ListGroup, ListGroupItem, FormCheck, Button } from "react-bootstrap";

interface UserListProps {
  setAllUserData: Dispatch<SetStateAction<Record<string, UserData>>>;
  users: User[];
}

export function UserList({ setAllUserData, users }: UserListProps) {
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [selectAllInput, setSelectAllInput] = useState<HTMLInputElement | null>(
    null
  );

  // Set indeterminate value for 'Select all' checkbox
  const isIndeterminate =
    selectedUsers.length > 0 && selectedUsers.length < users.length;
  useEffect(() => {
    if (isIndeterminate && selectAllInput !== null) {
      selectAllInput.indeterminate = isIndeterminate;
    }
  }, [isIndeterminate, selectAllInput]);

  return (
    <ListGroup className="userlist">
      <ListGroupItem>
        <FormCheck
          ref={setSelectAllInput}
          id={`user_li_all`}
          type="checkbox"
          label="Selecteer alle"
          checked={selectedUsers.length === users.length}
          onChange={(_) => {
            if (selectedUsers.length === users.length) {
              setSelectedUsers([]);
            } else {
              setSelectedUsers(users.map((u) => u.id));
            }
          }}
        />
      </ListGroupItem>
      {users.map((user) => (
        <UserListItem
          key={user.id}
          user={user}
          setUserData={(userData) => {
            setAllUserData((allUserData) => {
              if (userData === null) {
                const { [user.id]: _, ...filtered } = allUserData;
                return { ...filtered };
              } else {
                return { ...allUserData, [user.id]: userData };
              }
            });
          }}
          checked={selectedUsers.includes(user.id)}
          onChange={() => {
            if (selectedUsers.includes(user.id)) {
              setSelectedUsers(selectedUsers.filter((u) => u !== user.id));
            } else {
              setSelectedUsers([...selectedUsers, user.id]);
            }
          }}
        />
      ))}
      <ListGroupItem style={{ position: "sticky", bottom: 0 }}>
        <form
          action="/admin/export"
          method="POST"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <input
            type="hidden"
            name="user_ids"
            value={JSON.stringify(selectedUsers)}
          />
          <Button
            type="submit"
            variant="primary"
            disabled={selectedUsers.length === 0}
          >
            Export
          </Button>
        </form>
      </ListGroupItem>
    </ListGroup>
  );
}

interface UserListItemProps {
  user: User;
  checked: boolean;
  setUserData(userData: UserData | null): void;
  onChange(): void;
}

function UserListItem({
  user,
  checked,
  setUserData,
  onChange,
}: UserListItemProps) {
  const [loadState, setLoadState] = useState<
    "no_data" | "loading" | "loaded" | "error"
  >("no_data");

  useAsyncEffect(
    async (signal) => {
      if (!checked) {
        setLoadState("no_data");
        setUserData(null);
      } else {
        try {
          setLoadState("loading");

          const userData = userDataFromApi(
            await ApiService.userData(`${user.id}`)
          );
          if (signal.aborted) return;

          setLoadState("loaded");
          setUserData(userData);
        } catch {
          setLoadState("loaded");
        }
      }
    },
    [checked]
  );

  return (
    <ListGroupItem className={`user ${loadState}`}>
      <FormCheck
        id={`user_li_${user.id}`}
        type="checkbox"
        label={`${user.name} ${user.area}`}
        checked={checked}
        onChange={onChange}
      />
    </ListGroupItem>
  );
}
