import { Observation as ApiObservation } from "../../api";
import { InputObservation as ApiInputObservation } from "../../api";
import Observation, { NewObservation } from "../Observation";
import Indicator from "../Indicator";

export function newObservationToApi(o: NewObservation): ApiInputObservation {
  return {
    indicator: o.indicator?.code!,
    coordinate: o.coordinate,
    entries: o.entries.map((e) => {
      let entry: {
        id?: number;
        comment: string;
        photo?: string;
      } = {
        comment: e.comment,
      };

      if (e.photo instanceof File) {
        entry.photo = e.photo.name;
      }
      if (e.id !== null) {
        entry.id = e.id;
      }
      return entry;
    }),
    bag_id: o.bagId ?? undefined,
    address: {
      street: o.address.street,
      house_number: o.address.houseNumber,
      city: o.address.city,
      postcode: o.address.postcode.replace(/ /g, ''),
      is_exact: o.address.isExact,
    },
  };
}

export function observationFromApi(
  o: ApiObservation,
  indicator: Indicator
): Observation {
  return {
    id: o.id,
    bagId: o.bag_id ?? null,
    coordinate: o.coordinate,
    indicator: indicator,
    entries: o.entries.map((e) => ({
      id: e.id,
      photo: e.photo,
      comment: e.comment,
      created: new Date(e.created),
    })),
    address: {
      street: o.address.street,
      houseNumber: o.address.house_number,
      city: o.address.city,
      postcode: o.address.postcode,
      isExact: o.address.is_exact,
    },
  };
}
