import { FormEvent, FormEventHandler, useRef, useState } from "react";
import "./Login.scss";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Header from './header/Header';

import { MapContainer, TileLayer, useMap } from "react-leaflet";
import { Container, InputGroup } from "react-bootstrap";
import Footer from "./footer/Footer";
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

interface LoginProps {
  onLogin(password: string): Promise<void>;
}

export default function Login({ onLogin }: LoginProps): JSX.Element {
  const [password, setPassword] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);
  const ref = useRef<HTMLFormElement>(null);

  async function login(e: FormEvent) {
    e.preventDefault();

    try {
      setLoggingIn(true);
      await onLogin(password);
    } finally {
      if (ref.current) {
        setLoggingIn(false);
      }
    }
  }

  return (
    <Container fluid style={{ padding: "0px" }}>
      <Header />

      <MapContainer
        center={{ lat: 52.0841037, lng: 4.9424092 }}
        zoom={8}
        scrollWheelZoom={false}
        style={{
          height: "100vh",
          width: "100vw",
          filter: "blur(8px)",
          zIndex: "-1",
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>

      <Form className="form-container" onSubmit={login}>
        <Container className="flexbox-center">
          <img className="login-image" src="/logo512.png" alt="logo" />
        </Container>

        <p style={{ marginTop: 10 }}>
          Login om toegang te krijgen tot de applicatie.
        </p>

        <Form.Group className="mb-3" controlId="loginForm.password">
          <InputGroup>
            <Form.Control
              type="password"
              placeholder="Wachtwoord"
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            <InputGroupText style={{ background: "#e9ecef", color: "#495057" }}>
              <FontAwesomeIcon icon={faLock} />
            </InputGroupText>
          </InputGroup>
        </Form.Group>

        <Form.Group>
          <Button type="submit" className="float-end">
            Inloggen
          </Button>
        </Form.Group>
      </Form>
      <Footer />
    </Container>

    // <form className="login" onSubmit={login} ref={ref}>
    //   <img src="/logo512.png" alt="logo" />
    //   <label htmlFor="password">Wachtwoord:</label>
    //   <input
    //     id="password"
    //     disabled={loggingIn}
    //     type="password"
    //     value={password}
    //     onChange={(e) => setPassword(e.currentTarget.value)}
    //   />
    //   <button type="submit" disabled={loggingIn || password.length === 0}>
    //     Inloggen
    //   </button>

    //   <Link to="/terms">Gebruikersvoorwaarden</Link>
    // </form>
  );
}
