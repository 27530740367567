import React, { useRef, useState } from "react";
import { useBottomHeight } from "./IndicatorContext";
import "./EditObservation.scss";
import { useSnackbar } from "react-simple-snackbar";
import Observation, { NewObservation } from "../models/Observation";
import IndicatorCategory from "../models/IndicatorCategory";
import EditEntry from "./EditEntry";
import ChooseIndicator from "./ChooseIndicator";
import AllEntries from "./AllEntries";
import useAsyncCallback from "../utils/useAsyncCallback";
import { ApiService, InputObservation, OpenAPI } from "../api";
import { Observation as ApiObservation } from "../api/models/Observation";
import {
  observationFromApi,
  newObservationToApi,
} from "../models/api/Observation";
import useAsyncEffect from "../utils/useAsyncEffect";
import { addressFromApi } from "../models/api/Address";
import { Button } from "react-bootstrap";
interface EditObservationProps {
  categories: IndicatorCategory[];
  observation: Observation | NewObservation;
  readonly: boolean;
  onObservationChanged(o: Observation | NewObservation): void;
  onObservationSaved(o: Observation): void;
  onObservationDeleted(id: string): void;
}

export default function EditObservation({
  categories,
  observation,
  readonly,
  onObservationChanged,
  onObservationSaved,
  onObservationDeleted,
}: EditObservationProps): JSX.Element {
  const choosingIndicator = observation.indicator === null;
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const working = saving || deleting;
  const hasNewEntries = observation.entries.some((e) => e.id == null);

  const [savedSnackbar] = useSnackbar({
    style: {
      backgroundColor: "darkgreen",
    },
  });
  const [deletedSnackbar] = useSnackbar({
    style: {
      backgroundColor: "darkred",
    },
  });

  const editHeight = 50;

  useBottomHeight(choosingIndicator ? 75 : editHeight);

  const [activeEntry, setActiveEntry] = useState<number | null>(0);

  /**
   * Create new empty entry for the user to fill
   */
  function onNewObservationEntry() {
    onObservationChanged({
      ...observation,
      entries: [
        { id: null, comment: "", created: new Date(), photo: null },
        ...observation.entries,
      ],
    });
    setActiveEntry(0);
  }

  const latestObservation = useRef<Observation | NewObservation>(observation);
  latestObservation.current = observation;
  useAsyncEffect(
    async (ct) => {
      if (!latestObservation.current.address.isExact && !readonly) {
        const address = addressFromApi(
          await ApiService.geocode(
            observation.coordinate.lat,
            observation.coordinate.lng
          )
        );
        if (ct.aborted) return;
        onObservationChanged({ ...latestObservation.current, address });
      }
    },
    [observation.coordinate, readonly]
  );

  const save = useAsyncCallback(
    async (signal) => {
      setSaving(true);
      try {
        const saveObservation: InputObservation =
          newObservationToApi(observation);
        const data = new FormData();
        data.set("json", JSON.stringify(saveObservation));
        for (const entry of observation.entries) {
          if (entry.photo instanceof File) {
            data.append("photo", entry.photo);
          }
        }

        const response = await window.fetch(
          `${OpenAPI.BASE}/api/observations/${
            "id" in observation ? `${observation.id}/` : ""
          }`,
          {
            signal,
            method: "id" in observation ? "PUT" : "POST",
            headers: new Headers({ Authorization: `Bearer ${OpenAPI.TOKEN}` }),
            body: data,
          }
        );
        const responseObservation = (await response.json()) as ApiObservation;

        savedSnackbar("Opgeslagen", 1000);
        onObservationSaved(
          observationFromApi(responseObservation, observation.indicator!)
        );
      } catch (e) {
        alert(e);
      } finally {
        setSaving(false);
      }
    },
    [observation]
  );

  const _delete = useAsyncCallback(
    async (signal) => {
      try {
        if (!("id" in observation)) return;
        if (!window.confirm("Wil je deze observatie verwijderen?")) {
          return;
        }
        setDeleting(true);
        await ApiService.destroyObservation(observation.id);
        deletedSnackbar("Observatie verwijderd", 1000);
        if (onObservationDeleted !== undefined) {
          onObservationDeleted(observation.id);
        }
      } finally {
        if (!signal.aborted) {
          setDeleting(false);
        }
      }
    },
    [onObservationDeleted, deletedSnackbar, setDeleting]
  );

  if (observation.indicator === null) {
    return (
      <ChooseIndicator
        categories={categories}
        onIndicatorChosen={(indicator) => {
          onObservationChanged({ ...observation, indicator: indicator });
        }}
      />
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: `100%`,
          gap: "0.5rem",
        }}
      >
        <Button
          disabled={working}
          variant="secondary"
          onClick={() => {
            onObservationChanged({ ...observation, indicator: null });
          }}
        >
          {observation.indicator.code}: {observation.indicator.description}
        </Button>
        <p className="address">
          {observation.address.street !== ""
            ? `${!observation.address.isExact ? "nabij " : ""}${
                observation.address.street
              } ${observation.address.houseNumber}`
            : " "}
        </p>

        {activeEntry !== null ? (
          <EditEntry
            working={working}
            readonly={readonly}
            entry={observation.entries[activeEntry]}
            onEntryChanged={(newEntry) => {
              onObservationChanged({
                ...observation,
                entries: observation.entries.map((e) =>
                  e.id === newEntry.id ? newEntry : e
                ),
              });
            }}
            buttons={
              <div className="observation_buttons">
                {hasNewEntries || readonly ? null : (
                  <Button onClick={onNewObservationEntry} size="sm" variant="secondary">
                    Nieuwe observatie
                  </Button>
                )}
                {observation.entries.filter((e) => e.id !== null).length > 1 ? (
                  <Button
                    onClick={() => setActiveEntry(null)}
                    variant="secondary"
                    size="sm"
                  >
                    Alle observaties
                  </Button>
                ) : null}
              </div>
            }
          />
        ) : (
          <AllEntries
            entries={observation.entries}
            setEntries={(entries) =>
              onObservationChanged({ ...observation, entries })
            }
            setActiveEntry={setActiveEntry}
          />
        )}

        {readonly ? null : (
          <div className="d-flex gap-2 p-2 justify-content-end">
            {"id" in observation ? (
              <Button disabled={working} variant="danger" onClick={_delete}>
                Verwijderen
              </Button>
            ) : null}
            <Button variant="primary" disabled={working} onClick={save}>
              Opslaan
            </Button>
          </div>
        )}
      </div>
    );
  }
}
