import { useEffect, useRef } from "react";
import { GeolocateControl } from "react-map-gl";
import type mapboxgl from 'mapbox-gl';

export default function GeoLocationMarker() {
  const geoControlRef = useRef<mapboxgl.GeolocateControl>(null);

  // const mapRef = useMap();
  useEffect(() => {
    geoControlRef?.current?.trigger();
  }, [geoControlRef.current]);

  return (
    <GeolocateControl
      style={{ display: "none" }}
      showUserHeading={true}
      ref={geoControlRef}
      positionOptions={{ enableHighAccuracy: true }}
      trackUserLocation={true}
    />
  );
}
