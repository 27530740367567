import { Container, Row, Col } from "react-bootstrap";
import "./Footer.scss";

export default function Footer() {
  return (
    <footer className="fixed-bottom footer-container">
      <Container fluid>
        <Row>
          <Col></Col>
          <Col>
            Deze tool is eigendom van Avans Hogeschool
            <br />
            ©2024
          </Col>
          <Col className="text-end">
            <a href="/terms">Gebruikersvoorwaarden</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
