import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./EditEntry.scss";
import ObservationEntry from "../models/ObservationEntry";
import formatEntryDate from "../utils/formatEntryDate";
import useAsyncMemo from "../utils/useAsyncMemo";
import { OpenAPI } from "../api";
import cameraSvg from "./images/camera.svg";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

interface EditEntryProps {
  entry: ObservationEntry;
  onEntryChanged(entry: ObservationEntry): void;
  readonly: boolean;
  working: boolean;
  buttons: ReactNode;
}

export default function EditEntry({
  entry,
  onEntryChanged,
  readonly,
  working,
  buttons,
}: EditEntryProps): JSX.Element {
  const created = useMemo(
    () => formatEntryDate(entry.created),
    [entry.created]
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const [displayedButton, setDisplayedButton] = useState<JSX.Element | null>(
    null
  );

  function handleButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (!inputRef?.current) return;

    inputRef.current.click();
  }
  const photoSrc =
    useAsyncMemo(
      async (signal) => {
        if (entry.photo === null) {
          return "";
        } else if (entry.photo instanceof File) {
          return URL.createObjectURL(entry.photo);
        } else {
          const response = await window.fetch(
            `${OpenAPI.BASE}/api/entries/${entry.id}/photo${entry.photo}?thumbnail`,
            {
              signal,
              headers: new Headers({
                Authorization: `Bearer ${OpenAPI.TOKEN}`,
              }),
            }
          );
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        }
      },
      [entry.photo, entry.id]
    ) ?? "";

  function photoSelected(e: ChangeEvent<HTMLInputElement>) {
    onEntryChanged({ ...entry, photo: e.target.files![0] });
  }
  useEffect(() => {
    if (!photoSrc) {
      setDisplayedButton(
        <>
          <Button
            style={{
              position: "absolute",
              right: "0.25rem",
              bottom: "0.25rem",
            }}
            variant="secondary"
            onClick={handleButtonClick}
          >
            <FontAwesomeIcon icon={faCamera} />
          </Button>
          <input ref={inputRef} type="file" hidden onChange={photoSelected} />
        </>
      );
      return;
    }
    setDisplayedButton(
      <>
        <input
          id={`photo-${entry.id}`}
          type="file"
          accept="image/png, image/jpeg"
          onChange={photoSelected}
        />
        <label
          htmlFor={`photo-${entry.id}`}
          className={`photo${entry.photo !== null ? " exists" : ""}`}
        >
          <img
            src={photoSrc}
            style={{ border: "1px solid gray", borderRadius: "0.5rem" }}
            alt="Upload"
          />
        </label>
      </>
    );
  }, [photoSrc]);

  return (
    <div className="edit_entry">
      <span className="date">{created}</span>

      {readonly ? (
        entry.photo !== null ? (
          <button
            className="photo exists"
            onClick={() => {
              window
                .open(`/api/entries/${entry.id}/photo${entry.photo}`, "_blank")
                ?.focus();
            }}
          >
            <img src={photoSrc} alt="observation" />
          </button>
        ) : null
      ) : (
        displayedButton
      )}
      <Form.Control
        placeholder="Toelichting..."
        style={{ resize: "none" }}
        value={entry.comment}
        autoFocus={entry.id === null}
        readOnly={readonly}
        disabled={working}
        as="textarea"
        rows={3}
        onChange={(e) => onEntryChanged({ ...entry, comment: e.target.value })}
      />

      {buttons}
    </div>
  );
}
