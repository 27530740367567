import { Navbar, Container } from "react-bootstrap";

export default function Header() {
  return (
    <Navbar bg="primary" expand="lg">
      <Container className="px-2 mx-0">
        <Navbar.Brand href="#home" style={{ color: "white" }}>
          Signaleringstool
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}
